import React, { useRef, useState } from 'react';
import Img from 'gatsby-image';

import { AboutUsTypes } from 'types';

import {
  HistoryContentWrapper,
  HistoryContentOuter,
  HistoryDate,
  HistoryItem,
  HistoryList,
  HistoryTitle,
  HistoryWrapper,
  Title,
} from './styled';

type HistoryData = AboutUsTypes.HistoryInterface;

const History = ({ data }: { data: HistoryData[] }) => {
  const [selectedIndex, setSelectedIndex] = useState(data.length - 1);
  const historySelectedItem = useRef<HTMLLIElement | null>(null);
  const mappedData: HistoryData[] = data
    .map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ({ our_history }: any) => our_history,
    )
    .sort((a, b) => a.order - b.order);

  return (
    <HistoryWrapper>
      <Title>Our history</Title>

      <HistoryList>
        {mappedData.map(({ title, image, subtitle }, index) => {
          const isSelected = index === selectedIndex;
          const lastIndex = mappedData.length - 1;
          const rightLimit = lastIndex - 1;
          const right = index >= rightLimit;
          const openPrev =
            index + 1 === rightLimit && selectedIndex >= rightLimit;

          return (
            <HistoryItem
              onClick={() => setSelectedIndex(index)}
              key={title}
              index={index}
              ref={isSelected ? historySelectedItem : null}
              right={right}
              isSelected={isSelected}
              openPrev={openPrev}
            >
              <HistoryDate isSelected={isSelected}>{title}</HistoryDate>
              <HistoryContentOuter>
                <HistoryContentWrapper
                  isSelected={isSelected}
                  right={right}
                  widthFactor={right ? lastIndex - index : index}
                >
                  <Img fluid={{ ...image.localFile.childImageSharp.fluid }} />
                  <HistoryTitle>{subtitle}</HistoryTitle>
                </HistoryContentWrapper>
              </HistoryContentOuter>
            </HistoryItem>
          );
        })}
      </HistoryList>
    </HistoryWrapper>
  );
};

export default History;
