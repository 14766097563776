import styled from 'styles/styled';
import Img, { FluidObject } from 'gatsby-image';

import { Button } from 'components/UI/Button';
import sideImage from 'images/about-us/sides.png';

const PADDING = 18;
const itemWidth = '38px';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  padding: ${PADDING}px;
  width: 100%;

  @media ${({ theme }) => theme.devices.medium} {
    padding: 60px 0 0;
  }
`;

const Section = styled.section`
  margin-left: auto;
  margin-right: auto;

  & + & {
    margin-top: 50px;
  }

  @media ${({ theme }) => theme.devices.medium} {
    & + & {
      margin-top: 90px;
    }
  }
`;

export const VideoWrapper = styled(Section)`
  box-shadow: 0 0 60px 0 rgba(0, 127, 226, 0.6);
  max-width: ${({ theme }) => theme.dimensions.contentMaxWidthMedium}px;
  margin: 0 auto;
  width: 100%;

  video {
    max-width: 100%;
    vertical-align: middle;
    width: 100%;
  }
`;

export const IntroWrapper = styled(Section)`
  max-width: ${({ theme }) => theme.dimensions.blogContentMaxWidth}px;
  text-align: center;

  @media ${({ theme }) => theme.devices.medium} {
    text-align: left;
  }
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;

  span,
  strong {
    display: block;
  }

  @media ${({ theme }) => theme.devices.medium} {
    font-size: 26px;
  }
`;

export const HistoryWrapper = styled(Section)`
  overflow: hidden;
  width: 100%;

  ${Title} {
    text-align: center;
  }

  @media ${({ theme }) => theme.devices.medium} {
    max-width: ${({ theme }) => theme.dimensions.contentMaxWidthMedium}px;
    padding: 0 ${PADDING}px;
    width: 100%;

    ${Title} {
      text-align: left;
    }
  }
`;

export const HistoryList = styled.ul`
  display: flex;
  position: relative;
`;

export const HistoryItem = styled.li<{
  isSelected: boolean;
  openPrev: boolean;
  right: boolean;
  index: number;
}>`
  align-items: flex-start;
  border-left: 2px solid
    ${({ isSelected }) =>
      isSelected ? 'rgb(255, 255, 255)' : 'rgba(255, 255, 255, 0.4)'};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-basis: ${({ isSelected, openPrev, right }) =>
    (isSelected && !right) || openPrev ? '100%' : '0%'};
  flex-grow: 0;
  height: calc(50vw + 300px);
  transition: ${({ theme }) => theme.ui.transition('all')};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  @media ${({ theme }) => theme.devices.medium} {
    flex-basis: ${({ isSelected }) => (isSelected ? '100%' : '0%')};
    height: 600px;
    padding: 0;
  }
`;

export const HistoryDate = styled.span<{ isSelected: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.base};
  line-height: 1;
  margin-bottom: 30px;
  text-orientation: mixed;
  opacity: ${({ isSelected }) => (isSelected ? '1' : '0.5')};
  font-weight: ${({ isSelected, theme }) =>
    isSelected ? theme.fontWeight.bold : theme.fontWeight.light};
  padding: 0 10px;
  writing-mode: vertical-rl;
  transition: ${({ theme }) => theme.ui.transition('all')};

  @media ${({ theme }) => theme.devices.medium} {
    padding: 0 30px 0 10px;
  }
`;

export const HistoryContentOuter = styled.div`
  flex-grow: 1;
  position: relative;
  width: 100%;
`;

export const HistoryContentWrapper = styled.div<{
  isSelected: boolean;
  right: boolean;
  widthFactor: number;
}>`
  background-color: ${({ theme }) => theme.palette.dark};
  bottom: 0;
  opacity: ${({ isSelected }) => (isSelected ? '1' : '0')};
  pointer-events: ${({ isSelected }) => (isSelected ? 'all' : 'none')};
  transition: ${({ theme }) => theme.ui.transition('opacity')};
  position: absolute;
  top: 0;
  z-index: 1;
  left: ${({ right }) => (right ? 'auto' : '0')};
  right: ${({ right }) => (right ? 'calc(100% + 2px)' : 'auto')};
  width: ${({ widthFactor }) =>
    `calc(100vw - ${
      PADDING * 2
    }px - 2px - ${itemWidth} - (${itemWidth} * ${widthFactor}))`};

  @media ${({ theme }) => theme.devices.medium} {
    left: 0;
    right: auto;
    max-width: 100%;
    width: 100%;
  }

  .gatsby-image-wrapper {
    height: 50vw;

    @media ${({ theme }) => theme.devices.medium} {
      height: 300px;
    }
  }
`;

export const HistoryTitle = styled.h3`
  letter-spacing: 1px;
  padding: 15px 7.5px 0;
  font-size: ${({ theme }) => theme.fontSize.subtitleMobile};

  @media ${({ theme }) => theme.devices.medium} {
    font-size: ${({ theme }) => theme.fontSize.title};
    padding: 20px 13.5px 0;
  }
`;

export const EyalWrapper = styled(Section)`
  position: relative;
  padding-bottom: 30px;
  width: 100%;

  @media ${({ theme }) => theme.devices.large} {
    margin-top: 150px !important;
    padding: 30px ${PADDING}px;
  }
`;

export const FlexWrapper = styled.div`
  @media ${({ theme }) => theme.devices.large} {
    display: flex;
    margin: 0 auto;
    max-width: ${({ theme }) => theme.dimensions.contentMaxWidthMedium}px;
  }
`;

export const EyalBg = styled.div`
  background-color: #000e18;
  bottom: 0;
  content: '';
  left: -${PADDING}px;
  overflow: hidden;
  right: -${PADDING}px;
  top: 100px;
  position: absolute;

  @media ${({ theme }) => theme.devices.medium} {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    max-width: 100%;
    width: 100vw;
  }

  @media ${({ theme }) => theme.devices.large} {
    top: 0;

    &::after,
    &::before {
      content: '';
      background-image: url(${sideImage});
      background-size: cover;
      height: 100%;
      max-width: 100%;
      position: absolute;
      top: 0;
      width: 50vw;
    }

    &::before {
      background-position: 60% 100%;
      left: 0;
      transform: rotateZ(180deg);
    }

    &::after {
      background-position: 80% 100%;
      right: 0;
    }
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  text-align: center;

  @media ${({ theme }) => theme.devices.large} {
    display: flex;
    flex-basis: 55%;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
    text-align: left;

    ${Title} {
      text-align: left;
    }
  }
`;

export const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;

  .gatsby-image-wrapper {
    display: block;
    margin: 0 auto 10px;
    max-width: 400px;
    position: relative;
    z-index: 1;
  }

  &::after {
    background-image: linear-gradient(rgba(0, 14, 24, 0), rgba(0, 14, 24, 1));
    bottom: 0;
    content: '';
    height: 50px;
    left: -${PADDING}px;
    position: absolute;
    right: -${PADDING}px;
    z-index: 2;
  }

  @media ${({ theme }) => theme.devices.large} {
    flex-basis: 45%;
    overflow: initial;

    .gatsby-image-wrapper {
      bottom: -30px;
      left: 0;
      margin: 0;
      max-width: none;
      position: absolute !important;
      right: 0;
    }

    &::after {
      display: none;
    }
  }
`;

export const EyalSides = styled.div`
  bottom: 0;
  left: -${PADDING}px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: -${PADDING}px;
  top: 100px;
  z-index: 0;

  &::after,
  &::before {
    content: '';
    background-image: url(${sideImage});
    background-size: cover;
    height: 50vw;
    position: absolute;
    top: 0;
    width: 50vw;
  }

  &::before {
    background-position: 60% 100%;
    left: 0;
    transform: rotateZ(180deg);
  }

  &::after {
    background-position: 80% 100%;
    right: 0;
  }

  @media ${({ theme }) => theme.devices.large} {
    display: none;
  }
`;

export const StyledLink = styled(Button)`
  margin: 30px auto 0;

  @media ${({ theme }) => theme.devices.large} {
    margin-left: 0;
  }
`;

export const AwardsWrapper = styled(Section)``;

export const AwardsContainer = styled.div`
  margin: 0 auto;
  max-width: 300px;

  @media ${({ theme }) => theme.devices.small} {
    display: flex;
    max-width: 550px;
  }
`;

export const AwardContainer = styled.div`
  text-align: center;

  & + & {
    margin-top: 20px;
  }

  @media ${({ theme }) => theme.devices.small} {
    flex-basis: 50%;
    text-align: left;

    & + & {
      margin-top: 0;
      margin-left: 30px;
    }
  }
`;

export const AwardImg = styled.div`
  border: 1px solid #fff;
  margin-bottom: 10px;
  padding: 20px;
  text-align: center;

  img {
    height: 100px;
  }
`;

export const AwardTitle = styled.p`
  font-weight: 700;
  margin-bottom: 10px;
`;

export const BoxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const ReadWrapper = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

export const Box = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 225px;
  height: 126px;
  padding: 44px 13px 43px;
  margin: 5px;

  @media ${({ theme }) => theme.devices.medium} {
    margin: 10px;
  }
`;

export const BoxImage = styled(Img)<{ fluid: FluidObject }>`
  width: 100%;
  height: 100%;
`;

export const BoxBackground = styled.div`
  position: absolute;
  opacity: 0.1;
  background-color: #d8d8d8;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;
