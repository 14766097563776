import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { AboutUsTypes } from 'types';

import { ROUTES } from 'constants/common';
import History from 'components/AboutUs/History';
import videoMP3 from 'videos/Minebest_2021_Introduction.mp4';
import videoWEBM from 'videos/Minebest_2021_Introduction.webm';

import {
  Wrapper,
  IntroWrapper,
  Title,
  EyalBg,
  EyalSides,
  EyalWrapper,
  ContentWrapper,
  ImageWrapper,
  ReadWrapper,
  BoxesWrapper,
  Box,
  BoxBackground,
  BoxImage,
  VideoWrapper,
  StyledLink,
  FlexWrapper,
  AwardsWrapper,
  AwardContainer,
  AwardsContainer,
  AwardImg,
  AwardTitle,
} from './styled';

type READ_MORE_DATA = AboutUsTypes.ReadMoreInterface;
type MAPPED_READ_MORE_DATA = {
  read_about_us: READ_MORE_DATA;
  title: Pick<READ_MORE_DATA, 'title'>;
};

const AboutUsContent = () => {
  const {
    history,
    readAboutUs,
    page: {
      about_us: {
        aboutUsTitle,
        aboutUsIntro,
        eyalTitle,
        eyalTitlePrefix,
        eyalDesc,
      },
    },
  } = useStaticQuery(graphql`
    query AboutUs {
      history: allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { slug: { eq: "history" } } } }
        }
      ) {
        nodes {
          our_history {
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  id
                }
              }
            }
            subtitle
            title
            order
          }
          date
        }
      }

      readAboutUs: allWpPost(
        filter: {
          categories: {
            nodes: { elemMatch: { slug: { eq: "read-about-us" } } }
          }
        }
      ) {
        nodes {
          read_about_us {
            url
            order
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          title
        }
      }

      page: wpPage(slug: { in: "about-us" }) {
        about_us {
          aboutUsIntro
          aboutUsTitle
          eyalDesc
          eyalTitlePrefix
          eyalTitle
        }
      }
    }
  `);

  const mappedData: READ_MORE_DATA[] = readAboutUs.nodes
    .map(({ read_about_us: readAboutUs, title }: MAPPED_READ_MORE_DATA) => {
      return {
        ...readAboutUs,
        title,
      };
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .sort((a: any, b: any) => a.order - b.order);

  // const sliderData = ourMiners.nodes.map(
  //   ({ our_miners }: { our_miners: AboutUsTypes.OurMiners }) => our_miners,
  // );

  return (
    <Wrapper>
      <VideoWrapper>
        <video autoPlay controls loop muted>
          <source type="video/webm" src={videoWEBM} />
          <source type="video/mp4" src={videoMP3} />
        </video>
      </VideoWrapper>
      <IntroWrapper>
        <Title>{aboutUsTitle}</Title>
        <p dangerouslySetInnerHTML={{ __html: aboutUsIntro }} />
      </IntroWrapper>

      <History data={history.nodes} />

      <EyalWrapper>
        <EyalBg />
        <FlexWrapper>
          <ImageWrapper>
            <EyalSides />
            <StaticImage
              src="../../images/about-us/eyal_avramovich_ceo_minebest.png"
              alt=""
              placeholder="blurred"
            />
          </ImageWrapper>
          <ContentWrapper>
            <Title as="h1">
              <strong>{eyalTitlePrefix}</strong>
              <span>{eyalTitle}</span>
            </Title>
            <p>{eyalDesc}</p>
            <StyledLink as={Link} to={ROUTES.aboutEyal}>
              READ MORE
            </StyledLink>
          </ContentWrapper>
        </FlexWrapper>
      </EyalWrapper>

      <AwardsWrapper>
        <Title>Business Awards</Title>

        <AwardsContainer>
          <AwardContainer>
            <AwardImg>
              <StaticImage
                src="../../images/about-us/minebest_crypto_mining_company_award.png"
                alt=""
                placeholder="blurred"
              />
            </AwardImg>
            <AwardTitle>Best Reputation of Mining Farm</AwardTitle>
            <p>
              Winner of the International Digital Mining Summit (IDMS) in
              Schenzhen, China.
            </p>
          </AwardContainer>
          <AwardContainer>
            <AwardImg>
              <StaticImage
                src="../../images/about-us/minebest_bitcoin_farm_award.png"
                alt=""
                placeholder="blurred"
              />
            </AwardImg>
            <AwardTitle>Top Ten Mining Farms</AwardTitle>
            <p>
              Winner for Professional Design and Excellent Construction of World
              Digital Mining Summit 2019 (WDMS) in Frankfurt, Germany.
            </p>
          </AwardContainer>
        </AwardsContainer>
      </AwardsWrapper>

      <ReadWrapper>
        <Title>Read about us</Title>

        <BoxesWrapper>
          {mappedData.map(({ image, url, title }, key) => {
            return (
              <Box key={title + key}>
                <BoxImage
                  fluid={image?.localFile?.childImageSharp?.fluid}
                  alt={title}
                />
                <a href={url} target="_blank" rel="noreferrer noopener">
                  <BoxBackground />
                </a>
              </Box>
            );
          })}
        </BoxesWrapper>
      </ReadWrapper>
    </Wrapper>
  );
};

export default AboutUsContent;
