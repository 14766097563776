import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { theme } from 'styles/styled';
import { ROUTES } from 'constants/common';

import SEO from 'components/SEO';
import Layout from 'components/Layout';
import AboutUsContent from 'components/AboutUs';

const AboutUs = () => {
  const { wpPage } = useStaticQuery(graphql`
    query {
      wpPage(slug: { in: "about-us" }) {
        ...MetaData
      }
    }
  `);

  wpPage.seo.opengraphUrl = ROUTES.aboutUs;

  return (
    <Layout backgroundColor={theme.palette.dark}>
      <SEO metadata={wpPage || {}} />
      <AboutUsContent />
    </Layout>
  );
};

export default AboutUs;
